import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";

import {
  StyledWrapper,
  StyledContent,
  StyledLeftBlock,
  StyledRightBlock,
  StyledTitle,
  StyledSubtitle,
  StyledIphoneImage,
  StyledTextWrapper,
  StyledSectionImageOnSide,
  StyledSectionTitle,
  StyledSectionTitleLeftAligned,
  StyledSectionSubtitleLeftAligned,
  StyledSection1Image,
  StyledSection2Image,
  StyledSection3Image,
  StyledSection5Image,
  Styled3StepSection,
  StyledUserScreensSectionImage,
  StyledImageTextBelowWrapper,
  StyledListWrapper,
  EmailInput,
  EmailSubmit,
  StyledHorizontalSectionTextWrapper,
  StyledListItem,
  StyledFormWrapper,
  StyledUserScreensSectionText,
  StyledSectionImageOnSideNoBackground,
  StyledSectionNoBackground,
  StyledEmailSubmittedMessage,
} from "./Home.styled";

import { firebase } from "@firebase/app";
import "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDJ13hORlmKp6eJvhsHoRjW6ZjP6BRP2rI",
  authDomain: "gymsquad-d9ab9.firebaseapp.com",
  databaseURL: "https://gymsquad-d9ab9.firebaseio.com",
  projectId: "gymsquad-d9ab9",
  storageBucket: "gymsquad-d9ab9.appspot.com",
  messagingSenderId: "775391501590",
  appId: "1:775391501590:web:923cba56c1643418038437",
  measurementId: "G-ZP1Z0SJEZN",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
require("firebase/firestore");

const db = firebase.firestore();

const Home: React.FC = () => {
  const { t, i18n } = useTranslation("common");
  const [email, setEmail] = React.useState("");
  const [emailSubmitted, setEmailSubmitted] = React.useState(false);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await db.collection("landingPageEmail").add({
      email: email,
      dateAdded: firebase.firestore.Timestamp.fromDate(new Date()),
    });
    setEmailSubmitted(true);
  };

  return (
    <StyledWrapper>
      <Header />

      <StyledContent>
        <StyledLeftBlock>
          <StyledTextWrapper>
            <StyledTitle>
              <Trans t={t} i18nKey="home.title">
                <span style={{ color: "#00B0FF" }}>Monetize</span> your content
              </Trans>
            </StyledTitle>
            <StyledSubtitle>{t("home.subtitle1")}</StyledSubtitle>
            <StyledSubtitle>{t("home.emailSubmitMessage")}</StyledSubtitle>
            <StyledFormWrapper onSubmit={handleSubmit}>
              <EmailInput
                type="email"
                name="email"
                placeholder="Email..."
                onChange={handleChange}
              />
              <EmailSubmit type="submit" value={`${t("home.submit")}`} />
            </StyledFormWrapper>
            {emailSubmitted && (
              <StyledEmailSubmittedMessage>
                {t("emailSubmittedMessage")}
              </StyledEmailSubmittedMessage>
            )}
          </StyledTextWrapper>
        </StyledLeftBlock>
        <StyledRightBlock>
          <StyledIphoneImage
            src={`${process.env.PUBLIC_URL}/Home.png`}
            alt="homepage"
          />
        </StyledRightBlock>
      </StyledContent>

      <StyledSectionImageOnSide>
        <StyledSection1Image
          src={`${process.env.PUBLIC_URL}/${
            i18n.language === "fr" ? "IphoneBuilder.png" : "IphoneBuilder.png"
          }`}
          alt="homepage"
        />
        <StyledHorizontalSectionTextWrapper>
          <StyledSectionTitleLeftAligned>
            <Trans t={t} i18nKey="section1.title">
              We let you have your
              <span style={{ color: "#00B0FF" }}>own space</span> for you and
              your <span style={{ color: "#00B0FF" }}>community.</span>
            </Trans>
          </StyledSectionTitleLeftAligned>
          <StyledSectionSubtitleLeftAligned>
            {t("section1.subtitle")}
          </StyledSectionSubtitleLeftAligned>
        </StyledHorizontalSectionTextWrapper>
      </StyledSectionImageOnSide>

      <StyledSectionImageOnSideNoBackground>
        <StyledSection2Image
          src={`${process.env.PUBLIC_URL}/${
            i18n.language === "fr" ? "RevenueShare_fr.png" : "RevenueShare.png"
          }`}
          alt="homepage"
        />
        <StyledHorizontalSectionTextWrapper>
          <StyledSectionTitleLeftAligned>
            <Trans t={t} i18nKey="section2.title">
              We give you the <span style={{ color: "#00B0FF" }}>tools</span> to
              create your
              <span style={{ color: "#00B0FF" }}>subscription business</span>
            </Trans>
          </StyledSectionTitleLeftAligned>
          <StyledSectionSubtitleLeftAligned>
            {t("section2.subtitle")}
          </StyledSectionSubtitleLeftAligned>
          <StyledListWrapper>
            <StyledListItem>{t("section2.listItem1")}</StyledListItem>
            <StyledListItem>{t("section2.listItem2")}</StyledListItem>
            <StyledListItem>{t("section2.listItem3")}</StyledListItem>
            <StyledListItem>{t("section2.listItem4")}</StyledListItem>
          </StyledListWrapper>
        </StyledHorizontalSectionTextWrapper>
      </StyledSectionImageOnSideNoBackground>

      <StyledSectionImageOnSide>
        <StyledHorizontalSectionTextWrapper>
          <StyledSectionTitleLeftAligned>
            <Trans t={t} i18nKey="section3.title">
              Create your own <span style={{ color: "#00B0FF" }}>space</span>
              and track
              <span style={{ color: "#00B0FF" }}>your users</span>
            </Trans>
          </StyledSectionTitleLeftAligned>
          <StyledSectionSubtitleLeftAligned>
            {t("section3.subtitle")}
          </StyledSectionSubtitleLeftAligned>
          <StyledListWrapper>
            <StyledListItem>{t("section3.listItem1")}</StyledListItem>
            <StyledListItem>{t("section3.listItem2")}</StyledListItem>
          </StyledListWrapper>
        </StyledHorizontalSectionTextWrapper>
        <StyledSection3Image
          src={`${process.env.PUBLIC_URL}/${
            i18n.language === "fr" ? "trends.png" : "trends.png"
          }`}
          alt="homepage"
        />
      </StyledSectionImageOnSide>

      <StyledSectionNoBackground>
        <StyledSectionTitle>
          <Trans t={t} i18nKey="section4.title">
            "We give your community the
            <span style={{ color: "#00B0FF" }}>tools</span> to train more
            <span style={{ color: "#00B0FF" }}>efficiently</span>
          </Trans>
        </StyledSectionTitle>
        <Styled3StepSection>
          <StyledImageTextBelowWrapper>
            <StyledUserScreensSectionImage
              src={`${process.env.PUBLIC_URL}/UserScreen1.png`}
              alt="homepage"
            />
            <StyledUserScreensSectionText>
              {t("section4.listItem1")}
            </StyledUserScreensSectionText>
          </StyledImageTextBelowWrapper>
          <StyledImageTextBelowWrapper>
            <StyledUserScreensSectionImage
              src={`${process.env.PUBLIC_URL}/UserScreen2.png`}
              alt="homepage"
            />
            <StyledUserScreensSectionText>
              {t("section4.listItem2")}
            </StyledUserScreensSectionText>
          </StyledImageTextBelowWrapper>
          <StyledImageTextBelowWrapper>
            <StyledUserScreensSectionImage
              src={`${process.env.PUBLIC_URL}/UserScreen3.png`}
              alt="homepage"
            />
            <StyledUserScreensSectionText>
              {t("section4.listItem3")}
            </StyledUserScreensSectionText>
          </StyledImageTextBelowWrapper>
        </Styled3StepSection>
      </StyledSectionNoBackground>

      <StyledSectionImageOnSide>
        <StyledHorizontalSectionTextWrapper>
          <StyledSectionTitleLeftAligned>
            <Trans t={t} i18nKey="section5.title">
              What we plan <span style={{ color: "#00B0FF" }}>plan</span>
              to add next and our
              <span style={{ color: "#00B0FF" }}>mission</span>
            </Trans>
          </StyledSectionTitleLeftAligned>
          <StyledSectionSubtitleLeftAligned>
            {t("section5.subtitle")}
          </StyledSectionSubtitleLeftAligned>
          <StyledListWrapper>
            <StyledListItem>{t("section5.listItem1")}</StyledListItem>
            <StyledListItem>{t("section5.listItem2")}</StyledListItem>
            <StyledListItem>{t("section5.listItem3")}</StyledListItem>
          </StyledListWrapper>
        </StyledHorizontalSectionTextWrapper>

        <StyledSection5Image
          src={`${process.env.PUBLIC_URL}/MissionNextScreens.png`}
          alt="homepage"
        />
      </StyledSectionImageOnSide>

      <Footer />
    </StyledWrapper>
  );
};

export default Home;
