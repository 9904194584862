import React from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  StyledWrapper,
  StyledItem,
  StyledAllRights,
  StyledFooterContent,
} from './Footer.styled';
import StyledLink from '../StyledLink';

const Footer: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <StyledWrapper>
      <StyledFooterContent>
        <StyledAllRights>© Gymsquad 2021</StyledAllRights>
        <StyledLink to="/terms">
          <StyledItem>{t('footer.terms')}</StyledItem>
        </StyledLink>
        <StyledLink to="/privacy">
          <StyledItem>{t('footer.privacy')}</StyledItem>
        </StyledLink>
      </StyledFooterContent>
    </StyledWrapper>
  );
};

export default withRouter(Footer);
