import styled from 'styled-components';

export const StyledWrapper = styled.div`
  height: 200px;
`;

export const StyledHeaderContent = styled.div`
  width: 80%;
  max-width: 1250px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const StyledTitle = styled.div`
  margin: 40px 0;
  font-size: 40px;
  font-weight: bolder;
  color: black;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: unset;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  &:hover {
    color: unset;
  }
`;

export const StyledSelect = styled.select`
  outline: none;
  border: none;
  height: 30px;
`;
