import styled from "styled-components";

export const StyledWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: white;

  @media (min-width: 768px) {
    min-height: 100vh;
  }
`;

export const StyledContent = styled.div`
  flex: 1;
  width: 80%;
  max-width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;

  @media (max-width: 768px) {
    width: 95%;
    flex-direction: column;
    padding: 30px 0;
  }
`;

export const StyledLogo = styled.img`
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
`;

export const StyledLeftBlock = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 50px;
    align-items: center;
  }
`;

export const StyledRightBlock = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 50px;
  }
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 60px;
  font-weight: bold;
  margin: 20px 0;
  color: black;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

export const StyledSubtitle = styled.h3`
  font-size: 20px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 30px;
  color: black;
`;

export const StyledIphoneImage = styled.img`
  max-height: 600px;
`;

export const StyledAppStoreDownload = styled.img`
  width: 200px;
`;

export const StyledSection = styled.div`
  flex: 1;
  width: 80%;
  max-width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background-color: #fbfbfd;
  margin: 10px auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const StyledSectionNoBackground = styled.div`
  flex: 1;
  width: 80%;
  max-width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  margin: 10px auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const StyledSectionImageOnSide = styled.div`
  flex: 1;
  width: 80%;
  max-width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 150px 0;
  background-color: #fbfbfd;
  margin: 10px auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const StyledSectionImageOnSideNoBackground = styled.div`
  flex: 1;
  width: 80%;
  max-width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 150px 0;
  margin: 10px auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const StyledSectionTitle = styled.h4`
  font-size: 40px;
  font-weight: 600;
  margin: 10px 0;
  color: black;
  width: 50%;
  text-align: center;

  @media (max-width: 768px) {
    width: 80%;
    font-size: 30px;
  }
`;

export const StyledHorizontalSectionTextWrapper = styled.div`
    width 80%;
    padding-left: 50px;
`;

export const StyledSectionTitleLeftAligned = styled.h4`
  font-size: 40px;
  font-weight: 600;
  margin: 0 150px 10px 0;
  color: black;
  text-align: left;

  @media (max-width: 768px) {
    width: 80%;
    font-size: 30px;
    text-align: center;
  }
`;

export const StyledSectionSubtitleLeftAligned = styled.h5`
  font-size: 16px;
  font-weight: 300;
  margin: 0 150px 10px 0;
  color: black;
  text-align: left;

  @media (max-width: 768px) {
    width: 80%;
    text-align: center;
  }
`;

export const StyledSectionSubtitle = styled.h5`
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0;
  color: black;
  width: 50%;
  text-align: center;

  @media (max-width: 768px) {
    width: 80%;
    text-align: center;
  }
`;

export const StyledSection1Image = styled.img`
  margin: 50px;
  width: 20%;
  padding: 0 50px;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const StyledSection2Image = styled.img`
  margin: 50px;
  width: 20%;
  padding: 0 50px;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const StyledSection3Image = styled.img`
  margin: 50px;
  width: 20%;
  padding: 0 50px;

  @media (max-width: 768px) {
    width: 60%;
  }
`;

export const StyledSection5Image = styled.img`
  margin: 50px;
  width: 40%;
  padding: 0 50px;

  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

export const StyledUserScreensSectionImage = styled.img`
  width: 80%;
  @media (max-width: 768px) {
  }
`;

export const StyledUserScreensSectionText = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
  text-align: center;
  @media (max-width: 768px) {
  }
`;

export const Styled3StepSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledImageTextBelowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledListWrapper = styled.ul`
  padding: 0;
  list-style-type: none;
`;

export const StyledListItem = styled.li`
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 5px 0 5px 30px;
  list-style: none;
  background-image: url(${process.env.PUBLIC_URL}/${"CheckIconBlue.png"});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
`;

export const StyledFormWrapper = styled.form`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const EmailInput = styled.input`
  flex: 1;
  border-radius: 4px;
  border: 1px solid #bfcfda;
  padding: 10px;
`;

export const EmailSubmit = styled.input`
  height: 40px;
  color: white;
  background: #00b0ff;
  border: none;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  margin-left: 15px;
  padding: 10px 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
  }
`;

export const StyledEmailSubmittedMessage = styled.p`
  color: #03bd5b;
`;
