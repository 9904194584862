import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;
  background-color: #242428;
`;

export const StyledFooterContent = styled.div`
  width: 80%;
  max-width: 1250px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  padding: 60px 0;
`;

export const StyledAllRights = styled.div`
  margin-right: 20px;
  font-weight: 200;
`;

export const StyledItem = styled.div`
  margin: 0 20px;
`;
