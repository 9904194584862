import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  StyledWrapper,
  StyledTitle,
  StyledHeaderContent,
  StyledLink,
  StyledSelect,
} from './Header.styled';

const Header: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const { i18n } = useTranslation('common');

  React.useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const handleLangChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Router>
      <StyledWrapper>
        <StyledHeaderContent>
          <StyledLink href="/">
            <StyledTitle>Gymsquad</StyledTitle>
          </StyledLink>
          <StyledSelect value={selectedLanguage} onChange={handleLangChange}>
            <option value="en">EN</option>
            <option value="fr">FR</option>
          </StyledSelect>
        </StyledHeaderContent>
      </StyledWrapper>
    </Router>
  );
};

export default Header;
