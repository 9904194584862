import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { Header } from './Components/Header';
import { Footer } from './Components/Footer';

import { Home } from './Pages/Home';
import { PrivacyPolicy } from './Pages/PrivacyPolicy';
import TermsOfUse from './Pages/TermsOfUse/TermsOfUse';

import common_en from './translations/en/common.json';
import common_fr from './translations/fr/common.json';

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

const resources = {
  en: {
    common: common_en,
  },
  fr: {
    common: common_fr,
  },
};

i18next.use(LanguageDetector).init({
  resources: resources,
  fallbackLng: 'en',
  detection: DETECTION_OPTIONS,
  interpolation: { escapeValue: false },
});

const App: React.FC = () => {
  return (
    <I18nextProvider i18n={i18next}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/privacy">
            <Header />
            <PrivacyPolicy />
            <Footer />
          </Route>

          <Route exact path="/terms">
            <Header />
            <TermsOfUse />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </I18nextProvider>
  );
};

export default App;
